import React from "react";
import { Box, Typography, Button } from "@mui/material";
import Layout from "../../components/Layout";
import { MetaData } from "../../components/common/meta";
import { PageProps } from "gatsby";
import bricks from "../../images/connect/bricks.svg";
import mesh from "../../images/mesh.svg";

import { Link as RouterLink } from "gatsby";
import Github from "../../images/github2.inline.svg";
import Breakpoint from "./Breakpoint";

const githubUrl = "https://github.com/firedancer-io/firedancer";

const firedancer = ({ location }: PageProps) => {
  return (
    <Layout>
      <MetaData title="Firedancer" location={location} type="website" />

      <Box
        component="span"
        sx={{
          position: "absolute",
          left: "65px",
          top: "200px",
          height: "calc(100% - 415px)",
          width: 0,
          borderLeft: "1px solid #4B608D",
          display: { xs: "none", xl: "block" },
          zIndex: -1,
          "&:before": {
            content: '""',
            position: "absolute",
            top: "-5px",
            left: "-8px",
            width: "15px",
            height: "5px",
            backgroundColor: "#1CE7C2",
          },
        }}
      />

      <Box component="div" sx={{ position: "relative" }}>
        <Box
          component="img"
          src={bricks}
          sx={{
            position: "absolute",
            left: 0,
            width: "100%",
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: -1,
            pointerEvents: "none",
          }}
        />

        <Box
          component="div"
          sx={{
            maxWidth: 963,
            mx: "auto",
            mt: { xs: 12, md: 20 },
            mb: { xs: 6, md: 12 },
            textAlign: "center",
          }}
        >
          <Typography sx={{ mb: 3 }} variant="h1">
            {" "}
            <Box component="span" sx={{ color: "#1CE7C2" }}>
              A Second
            </Box>{" "}
            Solana Validator
          </Typography>
          <Typography sx={{ maxWidth: 730, mx: "auto", fontSize: 16 }}>
            We are building a new validator client for Solana to increase its networking throughput, resilience, and efficiency.{" "}
          </Typography>
          <Button
            component={RouterLink}
            to={githubUrl}
            variant="outlined"
            endIcon={<Github />}
            sx={{ mt: 3, zIndex: 2 }}
          >
            .Explore on GitHub
          </Button>
        </Box>
      </Box>

      <Box component="div" sx={{
        position: "relative",
        height: "20vw"

      }}>
        <Box
          component="img"
          src={mesh}
          sx={{
            position: "absolute",
            width: "100%",
            mt: "-100px",
            transform: "scale(1.12)"
          }}
        />
      </Box>


      <Breakpoint sx={{}} />


    </Layout>
  );
};

export default firedancer;
